body {
  background: black;
  overflow: hidden;
}

.particle {
  position: fixed;
  border-radius: 80%;
  z-index: -1; /* Keep the particles behind other elements */
  overflow: hidden;
  pointer-events: none; /* Prevent particles from receiving mouse events */
}

#particle-container {
  box-sizing: border-box;
  width: 100vw; /* Set width to 100% of the viewport width */
  min-width: 100vw;
  height: 150vh; /* Set height to 100% of the viewport height */
  margin: auto;
  position: absolute; /* Set position to absolute */
  overflow: hidden;
  pointer-events: none;

}

@for $i from 1 through 10 {
  @keyframes particle-animation-#{$i} {
    100% {
      transform: translate3d(
        (random(90) * 1vw),
        (random(90) * 1vh),
        (random(100) * 1px)
      );
    }
  }

  .particle:nth-child(#{$i}) {
    animation: particle-animation-#{$i} 60s infinite;
    $size: random(200) + 50 + px;
    opacity: random(100) / 100;
    height: $size;
    width: $size;
    filter: blur(50px);
    animation-delay: -$i * 0.2s;
    transform: translate3d(
      (random(90) * 1vw),
      (random(90) * 1vh),
      (random(100) * 1px)
    );
    background: hsl(random(360), 70%, 50%);
  }
}
